@use "global" as g;

.c-card {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 0 16px;
  @include g.sp {
    flex-direction: column;
  }
  &__item {
    width: 50%;
    height: auto;
    text-align: center;
    border-radius: 10px;
    padding: 30px;
    background: g.$c-light-gray;
    @include g.sp {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
    &--lead {
      font-size: 1.6rem;
      font-weight: 500;
      @include g.sp {
        font-size: 1.2rem;
      }
    }
    &--title {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 30px;
      @include g.sp {
        margin-bottom: 20px;
        font-size: 1.6rem;
      }
    }
    &--set {
      gap: 10px;
      display: grid;
      &--text {
        width: 100%;
        padding: 6px 0;
        background: g.$c-base;
        border: 1px solid g.$c-border;
        font-size: 1.8rem;
        font-weight: 500;
        @include g.sp {
          font-size: 1.4rem;
        }
        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &:nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
    &:nth-child(2) {
      .c-card__item--set--text {
        &:nth-child(1) {
          grid-column-start: 4;
          grid-column-end: 1;
          padding: 22px 0;
          @include g.sp {
            padding: 15px 0;
          }
        }
        &:nth-child(2) {
          grid-column-start: 1;
          grid-column-end: 4;
          border: none;
          background: none;
        }
        &:nth-child(3) {
          grid-column-start: 1;
          grid-column-end: 4;
          padding: 22px 0;
          @include g.sp {
            padding: 15px 0;
          }
        }
      }
    }
  }
  &__item-attention {
    width: 100%;
    font-size: 1.2rem;
    color: g.$c-gray;
    font-weight: 400;
    text-align: right;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
