@use "global" as g;

@keyframes fv {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fv-img {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes scroll {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.p-top {
  .bg-wrap {
    background: g.$c-base;
  }
  &__fv {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      position: fixed;
      top: 0;
      width: 100%;
      height: 100vh;
      background: url(../../img/fv.jpg) no-repeat center / cover;
      animation: fv 2.5s ease 0s alternate none running;
      z-index: -1;
    }
    &__title-wrap {
      position: fixed;
      margin: auto;
      opacity: 0;
      animation: fv-img 2s ease 1.5s forwards;
      z-index: -1;
    }
    &__scroll {
      position: fixed;
      bottom: 30px;
      margin: 0 auto;
      color: g.$c-base;
      font-size: 1.4rem;
      font-weight: 700;
      z-index: -1;
      @include g.sp {
        left: 20px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 130%;
        right: 0;
        left: 0;
        margin: auto;
        width: 2px;
        height: 100%;
        background: g.$c-base;
        animation: scroll ease 1.5s infinite;
      }
    }
    &__title {
      font-size: 2rem;
      text-align: center;
      color: g.$c-base;
      margin-top: 30px;
      @include g.sp {
        font-size: 1.8rem;
      }
    }
    &__img {
      max-width: 216px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  &__about {
    @include g.sectionWidth;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 110px;
    z-index: 100;
    @include g.sp {
      flex-direction: column;
      margin: 60px auto 0 auto;
      padding-top: 60px;
    }
    &__text-wrap {
      max-width: 544px;
      width: 100%;
      margin-right: 8px;
      border: 5px solid #fff;
      .p-top__about__title {
        font-size: 3rem;
        font-weight: 900;
        line-height: 52px;
        @include g.sp {
          font-size: 2rem;
          line-height: 40px;
        }
      }
      .p-top__about__text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 28px;
        margin: 50px 0 30px 0;
        @include g.sp {
          font-size: 1.4rem;
          line-height: 24.5px;
        }
      }
      .p-top__about__attention {
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        line-height: 18px;
        color: g.$c-gray;
        text-align: left;
        @include g.sp {
          text-align: left;
        }
      }
    }
    &__img-wrap {
      position: relative;
      max-width: 536px;
      width: 100%;
      margin-top: 34px;
      z-index: 1;
      overflow: hidden;
      .p-top__about__img {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: auto;
          vertical-align: bottom;
        }
        &:nth-child(1) {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: bottom;
          }
        }
        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: 0 auto;
          z-index: -1;
          transform: scale(0.99);
          img {
            width: 100%;
          }
        }
      }
    }
  }
  &__slider,
  .swiper {
    pointer-events: none;
    padding-top: 120px;
    &__list,
    .swiper-wrapper {
      gap: 0 10px;
      transition-timing-function: linear;
      &__item,
      .swiper-slide {
        width: clamp(270px, 29vw, 399px) !important;
        height: auto;
        img {
          width: 100%;
        }
      }
    }
  }
  &__service {
    @include g.sectionWidth;
    &__title {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      font-size: 3rem;
      font-weight: 700;
      white-space: nowrap;
      @include g.sp {
        font-size: 2rem;
      }
    }
    &__line {
      display: block;
      width: 100%;
      height: 1px;
      background: g.$c-font;
      margin-left: 26px;
      @include g.sp {
        margin-left: 20px;
      }
    }
    &__plan {
      &__title {
        @include g.planTitle;
      }
    }
    &__spec {
      padding-top: 30px;
      @include g.sp {
        padding-top: 0;
      }
      .p-top__service__title {
        @include g.planTitle;
      }
      .p-top__service__list {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 16px;
        @include g.sp {
          flex-direction: column;
          gap: 20px;
        }
        &__item {
          &__img {
            position: relative;
            max-width: 352px;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 40px;
              height: 40px;
              border-bottom: 40px solid transparent;
              border-left: 40px solid g.$c-font;
              z-index: 100;
            }
            img {
              width: 100%;
              vertical-align: top;
            }
          }
          &__title {
            font-size: 1.8rem;
            font-weight: 700;
            margin: 20px 0 15px 0;
          }
          &__text {
            font-size: 1.5rem;
          }
        }
      }
    }
    &__table {
      &-wrap {
        display: flex;
        justify-content: center;
        padding: 30px;
        margin-top: 40px;
        background: g.$c-light-gray;
        @include g.sp {
          flex-direction: column;
          padding: 20px 16px;
        }
      }
      .p-top__service__table__list {
        &__item {
          display: flex;
          gap: 40px;
          font-size: 1.6rem;
          @include g.sp {
            flex-direction: column;
            gap: 0;
            margin-bottom: 20px;
          }
          &__title {
            margin-bottom: 10px;
            min-width: 150px;
            @include g.sp {
              margin-bottom: 8px;
              font-weight: 700;
            }
          }
        }
      }
      .right-list {
        border-left: 3px solid g.$c-base;
        padding: 0 60px 0 60px;
        @include g.sp {
          border-left: none;
          padding-left: 0;
        }
        .p-top__service__list--item__title {
          min-width: 50px;
        }
      }
    }
    &__outdoor {
      &__title {
        @include g.planTitle;
      }
      &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        @include g.sp {
          gap: 19px 11px;
        }
        .p-top__service__outdoor__item {
          display: flex;
          width: 49%;
          background: g.$c-light-gray;
          border: 2px solid g.$c-font;
          @media screen and (max-width: 1112px) {
            width: 100%;
          }
          @include g.sp {
            height: auto;
            width: calc((100% / 2) - 11px);
            flex-wrap: wrap;
          }
          .p-top__service__outdoor__img {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 40px;
              height: 40px;
              border-bottom: 50px solid transparent;
              border-left: 50px solid g.$c-font;
              z-index: 100;
            }
            @include g.sp {
              width: 100%;
            }
            img {
              max-width: 246px;
              height: 100%;
              vertical-align: bottom;
              @include g.sp {
                max-width: none;
                width: 100%;
                height: auto;
              }
            }
          }
          .p-top__service__outdoor__list-wrap {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 30px;
            @include g.sp {
              padding: 16px 15px;
            }
            .list__title {
              font-size: 1.8rem;
              font-weight: 700;
              padding-bottom: 11px;
              @include g.sp {
                font-size: 1.4rem;
              }
            }
            .list__item {
              display: flex;
              .list__text {
                font-size: 1.5rem;
                width: 100%;
                @include g.sp {
                  font-size: 1.2rem;
                }
                &:nth-child(2) {
                  width: 45%;
                }
              }
            }
          }
        }
      }
    }
  }
  &__parallax {
    width: 100%;
    height: 480px;
    margin-top: 80px;
    overflow: hidden;
    @include g.sp {
      height: 265px;
    }
    &__img {
      img {
        width: 100%;
      }
    }
  }
  &__other {
    @include g.sectionWidth;
    &__header {
      text-align: center;
      .p-top__other__title {
        font-size: 3rem;
        font-weight: 900;
        @include g.sp {
          font-size: 2rem;
        }
      }
      .p-top__other__text {
        font-size: 1.6rem;
        font-weight: 700;
        margin-top: 50px;
        @include g.sp {
          font-size: 1.4rem;
        }
      }
    }
    &__body {
      .p-top__other__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 80px;
        @include g.sp {
          flex-direction: column;
          margin-top: 60px;
        }
        .p-top__other__item {
          width: calc((100% / 2) - 16px);
          height: 191px;
          transition: 0.3s;
          @include g.sp {
            width: 100%;
          }
          &:hover {
            transition: 0.3s;
            opacity: 0.8;
          }
          &--link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 2.4rem;
            font-weight: 900;
            color: g.$c-base;
            letter-spacing: 0.1em;
            @include g.sp {
              font-size: 1.8rem;
            }
          }
          &:nth-child(1) {
            background: url(../../img/other/other01.jpg) no-repeat center /
              cover;
          }
          &:nth-child(2) {
            background: url(../../img/other/other02.jpg) no-repeat center /
              cover;
          }
          &:nth-child(3) {
            background: url(../../img/other/other03.jpg) no-repeat center /
              cover;
          }
          &:nth-child(4) {
            background: url(../../img/other/other04.jpg) no-repeat center /
              cover;
          }
        }
      }
    }
  }
  &__map {
    @include g.sectionWidth;

    &__title {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      font-size: 3rem;
      font-weight: 700;
      white-space: nowrap;
      @include g.sp {
        font-size: 2rem;
      }
    }
    &__line {
      display: block;
      width: 100%;
      height: 1px;
      background: g.$c-font;
      margin-left: 26px;
    }
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 40px;
      border-bottom: 1px solid g.$c-font;

      @include g.sp {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__list {
      width: 80%;
      margin-right: 58px;
      @include g.sp {
        width: 100%;
        margin-right: 0;
      }
      .p-top__map__item {
        display: flex;
        &--text {
          width: 100%;
          font-size: 1.6rem;
          margin-bottom: 16px;
          a {
            color: g.$c-font;
            transition: 0.3s;
            &:hover {
              transition: 0.3s;
              color: #797979;
            }
          }
        }
      }
    }
    &__map {
      max-width: 628px;
      width: 100%;
      @include g.sp {
        width: 100%;
      }
      iframe {
        width: 100%;
        height: 298px;
        @include g.sp {
          width: 100%;
        }
      }
    }
  }

  &__banner {
    max-width: clamp(303px, calc(536 / 1366 * 100vw), 536px);
    width: 100%;
    margin: clamp(40px, calc(60 / 1366 * 100vw), 60px) auto 0 auto;
    padding-bottom: clamp(40px, calc(60 / 1366 * 100vw), 60px);

    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
}
