@use "global" as g;

.p-privacy-policy {
  &__policy {
    margin-bottom: 140px;
    p {
      font-size: 1.5rem;
      line-height: calc(22.5 / 15);
    }

    @include g.sp {
      margin-bottom: 120px;
    }

    &__title {
      margin-bottom: 60px;
    }

    &__subtitle {
      margin-bottom: 30px;
    }

    section {
      margin-top: 60px;

      p + p {
        margin-top: 1.5em;
        letter-spacing: calc(50 / 1000 * 1em);
      }
    }
  }

  &__terms {
    margin-bottom: 100px;
    p {
      font-size: 1.5rem;
      line-height: calc(22.5 / 15);
    }

    @include g.sp {
      margin-bottom: 80px;
    }

    &__title {
      margin-bottom: 80px;
    }

    &__subtitle {
      margin-bottom: 30px;
    }

    section {
      &:not(:last-of-type) {
        margin-bottom: 80px;
      }

      p + p {
        margin-top: 1.5em;
      }
    }
  }

  &__others {
    p {
      font-size: 1.5rem;
      line-height: calc(22.5 / 15);
    }

    section {
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }

      h3 {
        font-size: 1.8rem;
        margin-bottom: 15px;
      }

      p {
        font-size: 1.5rem;
        line-height: calc(22.5 / 15);

        small {
          font-size: 1.2rem;
          color: g.$c-gray;
        }
      }
    }
  }
}
