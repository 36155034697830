@use "global" as g;

.c-button__cv {
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 235px;
  width: 100%;
  height: 70px;
  z-index: 100;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
  &--link {
    font-size: 2rem;
    font-weight: Bold;
    color: g.$c-base;
    text-decoration: none;
    img {
      width: 100%;
    }
  }
}
