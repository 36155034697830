@use "global" as g;

.p-lineup-caution {
  font-size: 1.5rem;
  line-height: calc(22.5 / 15);
  margin-bottom: 75px;

  @include g.sp {
    margin-bottom: 80px;
  }
}
