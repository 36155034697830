@use "variable" as v;
@use "responsive" as r;

// section幅
@mixin sectionWidth {
  max-width: 1128px;
  width: 100%;
  margin: 80px auto 0 auto;
  padding: 0 20px;
  @include r.sp {
    margin: 60px auto 0 auto;
  }
}

// font  ----------------------------------

// // planTitle
@mixin planTitle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 30px 0;
  @include r.sp {
    font-size: 1.6rem;
  }
  span {
    display: block;
    width: 16px;
    height: 16px;
    background: v.$c-font;
    margin-right: 10px;
    @include r.sp {
      width: 11px;
      height: 11px;
      margin-right: 5px;
    }
  }
}
