@use "global" as g;

.p-pricing {
  display: flex;
  flex-direction: column;

  &__container {
    margin-bottom: auto;
    width: 100%;
  }

  &__image {
    margin: 80px -20px 30px;
    overflow: auto;

    img {
      padding: 0 20px;
      width: calc(1088px + 20px * 2);
    }
  }

  &__note {
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }
}
