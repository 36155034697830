@mixin sp {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin tab {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 1024px) {
    @content;
  }
}

.sp-only {
  display: inherit;
  @include tab {
    display: none;
  }
  @include pc {
    display: none;
  }
}
.pc-only {
  display: inherit;
  @include sp {
    display: none;
  }
}
