@use "global" as g;

.c-accident {
  &__title {
    margin-bottom: 34px;
  }

  &__subtitle {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  &__text {
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }
}
