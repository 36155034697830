@use "global" as g;

.c-lower-head {
  width: 100%;
  height: 230px;
  @include g.sp {
    height: 173px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
