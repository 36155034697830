@use "global" as g;

.c-lower-title {
  display: flex;
  gap: 19px;
  align-items: center;

  &__text {
    font-size: 3rem;
    flex-shrink: 0;

    @include g.sp {
      font-size: 2rem;
    }
  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    background: g.$c-font;
    width: 100%;
  }
}

.c-lower-subtitle {
  display: flex;
  gap: 10px;

  &__text {
    font-size: 2.4rem;
    line-height: calc(36 / 24);
  }

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: g.$c-font;
    line-height: 1;
    flex-shrink: 0;
    margin-top: 10px;
  }
}
