@use "global" as g;

.u-attention {
  width: 100%;
  font-size: 1.2rem;
  color: g.$c-gray;
  font-weight: 600;
  text-align: right;
  margin-top: 10px;
}
