@use "global" as g;

.l-footer {
  width: 100%;
  color: g.$c-base;
  background: g.$c-font;
  padding: 60px 0 35px 0;
  transition: 0.3s;
  &__wrap {
    @include g.sectionWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    @include g.sp {
      flex-direction: column;
      margin-top: 0;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    img {
      max-width: 136px;
      width: 100%;
    }
    .l-footer__title {
      font-size: 2rem;
      font-weight: 700;
      margin-left: 30px;
      line-height: 34px;
      @include g.sp {
        font-size: 1.4rem;
      }
    }
  }
  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    @include g.sp {
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
    }
    .l-footer__item {
      .l-footer__link {
        font-size: 1.4rem;
        color: g.$c-base;
        &:hover {
        }
      }
    }
    &:nth-child(2) {
      margin-top: 20px;
      @include g.sp {
        flex-direction: row;
        margin-top: 35px;
      }
    }
  }
  &__copy {
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    text-align: right;
    margin-right: 30px;
    @include g.sp {
      text-align: center;
      margin-top: 60px;
      margin-right: 0;
    }
  }
}
