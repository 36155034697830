@use "global" as g;

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 0.05em;
  color: g.$c-font;
}
