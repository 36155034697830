@use "global" as g;

.p-insurance-basic {
  margin-top: 65px;
  margin-bottom: 80px;

  @include g.sp {
    margin-top: 60px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__image {
    margin: 0 -20px;
    overflow-x: auto;

    &__container {
      img {
        padding: 0 20px;
        width: calc(1088px + 20px * 2);

        @include g.sp {
          width: calc(549px + 20px * 2);
        }
      }
    }
  }
}

.p-insurance-caution {
  margin-bottom: 80px;

  &__title {
    margin-bottom: 34px;
  }

  &__list {
    li {
      padding-left: 1em;
      text-indent: -1em;
      font-size: 1.5rem;
      line-height: calc(22.5 / 15);
    }
  }

  &__note {
    margin-top: 24px;
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }
}

.p-insurance-plus {
  margin-bottom: 80px;

  &__title {
    margin-bottom: 15px;
  }

  &__text {
    margin-bottom: 30px;
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }

  &__image {
    margin: 0 -20px;
    overflow-x: auto;

    &__container {
      img {
        padding: 0 20px;
        width: calc(1088px + 20px * 2);

        @include g.sp {
          width: calc(549px + 20px * 2);
        }
      }
    }
  }
}

.p-insurance-wide {
  margin-bottom: 80px;

  &__title {
    margin-bottom: 15px;
  }

  &__text {
    margin-bottom: 30px;
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }

  &__image {
    margin: 0 -20px;
    overflow-x: auto;

    &__container {
      img {
        padding: 0 20px;
        width: calc(1088px + 20px * 2);

        @include g.sp {
          width: calc(549px + 20px * 2);
        }
      }
    }
  }
}

.p-insurance-system {
  margin-bottom: 80px;

  &__title {
    margin-bottom: 30px;
  }

  &__text {
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }

  &__note {
    margin-top: 8px;
    font-size: 1.2rem;
    letter-spacing: calc(100 / 1000 * 1em);
    line-height: calc(18 / 12);
    color: g.$c-gray;
  }
}
