@use "global" as g;

.c-lower-page {
  &__container {
    max-width: calc(1088px + 20px * 2);
    padding: 60px 20px 160px;
    margin: 0 auto;

    @include g.sp {
      padding-bottom: 140px;
    }
  }
}
