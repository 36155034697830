@use "global" as g;

.p-lineup-lead {
  margin-top: 80px;
  @include g.sp {
    margin-top: 60px;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 40px;
    font-weight: 900;
  }

  &__text {
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
    margin-bottom: 40px;

    p + p {
      margin-top: 1.5em;
    }

    @include g.sp {
      margin-bottom: 30px;
    }
  }

  &__table {
    overflow-x: auto;
    @include g.sp {
      margin-right: -20px;
    }

    &__container {
      padding: 30px 40px;
      border-radius: 10px;
      background-color: g.$c-light-gray;
      @include g.sp {
        width: 498px;
      }
    }

    img {
      width: 393px;
    }
  }

  &__info {
    margin-top: 30px;
    font-size: 1.5rem;
    line-height: calc(22.5 / 15);
  }
}
