@use "global" as g;

.p-lineup-list {
  &__container {
    margin: 140px 0;

    @include g.sp {
      margin: 120px 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 80px;

      @include g.sp {
        margin-bottom: 60px;
      }
    }

    &__title {
      font-size: 2.4rem;
      margin-bottom: 20px;

      @include g.sp {
        margin-bottom: 10px;
        font-size: 1.8rem;
      }
    }

    &__image {
      margin: 0 -20px;
      overflow-x: auto;
      &__container {
        img {
          padding: 0 20px;
          max-width: calc(1089px + 20px * 2);
        }
      }
    }
  }
}
