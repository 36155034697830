@use "global" as g;

.u-left-list {
  margin: 0 108px 0 0;
  @include g.sp {
    margin: 0;
  }
  .p-top__service__list--item__title {
    min-width: 150px;
  }
}
